import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { LazyLoadImage as Img } from 'react-lazy-load-image-component';
import { videoAutoplayObserver } from 'utils/imageDelay';

const BELOW_SMALL = window.screen.width < 640;
const BELOW_MEDIUM = window.screen.width < 768;
const BELOW_LARGE = window.screen.width < 1024;

const FrameImage = ({ isExternal = true, Image, src = '', alt, className = '', height, width, isVideoAutoplay = true, ...props }) => {
    // let placeholderSrc;
    if (Image) {
        alt = Image.alternativeText
        src = Image.url;
        height = Image.height;
        width = Image.width;
        // placeholderSrc = Image.formats?.thumbnail;

        if (BELOW_SMALL) {
            height = Image.formats?.small?.height;
            width = Image.formats?.small?.width;
            src = Image.formats?.small?.url || src;
        } else if (BELOW_MEDIUM) {
            height = Image.formats?.medium?.height;
            width = Image.formats?.medium?.width;
            src = Image.formats?.medium?.url || src;
        } else if (BELOW_LARGE) {
            height = Image.formats?.large?.height;
            width = Image.formats?.large?.width;
            src = Image.formats?.large?.url || src;
        }
    }
    let finalSrc = src;

    if (isExternal) {
        finalSrc = `${process.env.REACT_APP_API_DOMAIN}${src}`;
        // placeholderSrc = `${process.env.REACT_APP_API_DOMAIN}${placeholderSrc}`
    }

    const isVideo = Image?.mime === 'video/mp4';

    const videoElement = useRef();
    useEffect(() => {
        if (videoElement.current && isVideoAutoplay) {
            videoAutoplayObserver.observe(videoElement.current)
        }

        return () => {
            if (videoElement.current) {
                videoAutoplayObserver.unobserve(videoElement.current);
            }
        }
    }, [videoElement]);

    if (!src) {
        return <span className={`${className} bg-gray-300 text-white text-xl`}>Content Not Available</span>
    }

    if (isVideo) {
        return <video ref={videoElement} className={className} src={finalSrc} playsInline={isVideoAutoplay} autoPlay={isVideoAutoplay} loop={isVideoAutoplay} controls={!isVideoAutoplay} {...props}></video>
    }

    return <Img threshold={200} height={height} width={width} placeholder={<span className="bg-gray-300 text-white text-xl">Image Loading</span>} className={`${className}`} src={finalSrc} alt={alt} {...props}></Img>
}

const FrameL = ({ children, className = '', Image, src, alt, isExternal = true, imgClasses = '', hasImage = true, ratio = '16:9', ratioAboveSm, ratioAboveMd, ratioAboveLg, ratioAboveXl }) => {
    return <frame-l
        class={className}
        ratio={ratio}
        ratioAboveSm={ratioAboveSm}
        ratioAboveMd={ratioAboveMd}
        ratioAboveLg={ratioAboveLg}
        ratioAboveXl={ratioAboveXl}>
        {hasImage && <FrameImage className={imgClasses} Image={Image} src={src} alt={alt} isExternal={isExternal}></FrameImage>}
        {children}
    </frame-l>
};

FrameL.propTypes = {
    src: PropTypes.string,
    isExternal: PropTypes.bool,
};

FrameL.defaultProps = {
    src: '',
    isExternal: true,
    alt: '',
    className: '',
};

export default FrameL;
export { FrameImage as Img };

