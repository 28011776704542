import 'intersection-observer';

const options = {
    threshold: 0,
    rootMargin: "0px 0px 400px 0px"
}

function handleLoadDivImage(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            const div = entry.target;
            const src = div.getAttribute('data-src');
            console.log(src);
            div.style.backgroundImage = `url(${src})`
            observer.unobserve(div);
        }
    })
}

export const divImageObserver = new IntersectionObserver(handleLoadDivImage, options);


const videoOptions = {
    threshold: 0.4,
    rootMargin: "0px 0px 0px 0px"
}

function handleAutoPlayPause(entries, observer) {
    entries.forEach(entry => {
        const video = entry.target;
        if (entry.isIntersecting) {
            video.play().catch(e => {
                video.muted = true;
                video.play();
            });
        } else {
            video.pause();
        }
    })
}

export const videoAutoplayObserver = new IntersectionObserver(handleAutoPlayPause, videoOptions);
