import React, { useState, useEffect } from 'react';
import { IoMdMenu, IoMdClose, IoMdCall, IoIosPin } from 'react-icons/io';
import { Link, withRouter } from 'react-router-dom';

import moment from 'moment';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import { Img } from 'component/FrameL';
import './style.css';

function DesktopNavbar({ Logo = [], CoreData }) {
  const { PhoneLocal } = CoreData;
  const [isOpen, setIsOpen] = useState(true);
  const [bookingStart, setBookingStart] = useState(moment(new Date()));
  const [bookingEnd, setBookingEnd] = useState(moment(new Date()));
  const [bookingFocusedInput, setBookingFocusedInput] = useState(null);

  useEffect(() => {
    const handleScrollClose = () => {
      if (window.scrollY < 100) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }

    document.addEventListener('scroll', handleScrollClose);

    return () => {
      document.removeEventListener('scroll', handleScrollClose);
    }
  }, []);

  return (<>
    <div className="navbar__padding">
    </div>
    <div className={`fixed top-0 left-0 m-4 z-50 flex items-center`}>
      <button onMouseMove={() => {
          setIsOpen(true);
        }} 
        aria-label="Menu" 
        className="navbar-bg-color p-2 tailwind-block">
        <IoMdMenu className="text-4xl text-white"></IoMdMenu>
      </button>
      <div className="nav__contact-us ml-2 text-xl text-white">
        <span role="heading">Contact Us</span>
        <span>{PhoneLocal}</span>
      </div>
    </div>
    <div className={`navbar__bg-container ${isOpen ? 'visible' : ''}`}>
      <div className="xl:container navbar__content tracking-wide font-bold text-lg uppercase mx-auto flex justify-around items-end" role='navigation' >
        <Link to="/rooms">
          Rooms
            </Link>
        <Link to="/specials">
          Specials
            </Link>
        <Link to="/packages">
          Packages
          </Link>
        <Link to="/thingstodo">
          Activities
          </Link>
        <Link to="/" className="relative top-16 z-10 navbar__logo--width">
          <Img className="navbar__logo--width" Image={Logo} />
        </Link>
        <Link to="/dining">
          Dining
          </Link>
        <Link to="/gallery">
          Gallery
            </Link>
        <Link to="/about">
          About
        </Link>
        <Link to="/meetings">
          Meetings
        </Link>
        <Link to="/press">
          Press
        </Link>
      </div>
      <div className={`fixed mt-4 mr-4 top-0 right-0 flex flex-row z-10`}>
        <DateRangePicker
          startDate={bookingStart}
          startDateId="start"
          endDate={bookingEnd}
          endDateId="end"
          onDatesChange={({ startDate, endDate }) => {
            setBookingStart(startDate);
            setBookingEnd(endDate);
          }}
          focusedInput={bookingFocusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => setBookingFocusedInput(focusedInput)}
          >
        </DateRangePicker>
        <a
          href={`https://secure.thinkreservations.com/lookoutpointinn/reservations/availability?start_date=${bookingStart && bookingStart.format('YYYY-MM-DD')}&end_date=${bookingEnd && bookingEnd.format('YYYY-MM-DD')}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Booking Link"
          className="navbar-bg-color text-lg text-white mr-2 btn-sm">
          Book Now
        </a>
      </div>
    </div>
  </>
  );
}

function MobileNavbar({ Logo, CoreData }) {
  const [isOpen, setIsOpen] = useState(false);
  const { PhoneLocal } = CoreData;

  const [isBottomOpen, setIsBottomOpen] = useState(true);

  useEffect(() => {
    const handleScrollClose = (e) => {
      if (window.scrollY < 100) {
        setIsBottomOpen(true);
      } else {
        setIsBottomOpen(false);
      }
    }

    document.addEventListener('scroll', handleScrollClose);

    return () => {
      document.removeEventListener('scroll', handleScrollClose);
    }
  }, []);

  return (<div className="fixed z-20">
    <div className={`fixed p-4 top-0 left-0 right-0 flex justify-between items-center pointer-events-none`}>
      <button className="pointer-events-auto p-2 bg-primary-blue text-white" aria-label="Menu" onClick={() => {
        setIsOpen(true);
      }}>
        <IoMdMenu className="icon"></IoMdMenu>
      </button>
      <Link className="pointer-events-auto w-24 sm:w-40" to="/" aria-label="Home">
        <Img Image={Logo} />
      </Link>
      <div className="w-8"></div>
    </div>
    <div className={` p-4 mobile-nav__bottom ${isBottomOpen ? 'visible' : ''}`}>
      <cluster-l>
        <div>
          <a className="p-2 bg-primary-blue text-white" 
            aria-label="Call Hotel Number"
            href={`tel:${PhoneLocal}`}
            >
            <IoMdCall className="icon" />
          </a>

          <a className="text-2xl py-2 px-4 bg-primary-blue text-white" 
            href="https://secure.thinkreservations.com/lookoutpointinn/reservations/"
            rel="noopener noreferrer"
            aria-label="Booking Link"
            target="_blank"
            >
            Book Now
          </a>

          <a className="p-2 bg-primary-blue text-white"
            aria-label="Link to Google Maps"
            href="https://www.google.com/maps/place/Lookout+Point+Lakeside+Inn/@34.4273632,-93.0845443,15z/data=!4m2!3m1!1s0x0:0xb6dd1b1bad849469"
            rel="noopener noreferrer"
            target="_blank"
            >
            <IoIosPin className="icon" />
          </a>

        </div>
      </cluster-l>
    </div>

    <div className={`navbar__link text-xl uppercase bg-gray-200 flex-col flex items-center p-8 py-16 mobile-nav__container ${isOpen ? 'visible' : ''}`}>
      <IoMdClose className="absolute top-0 right-0 icon text-primary-blue m-8" onClick={() => {
        setIsOpen(false);
      }} />
      <Link to="/" onClick={() => {
        setIsOpen(false);
      }}>
        <Img className="navbar__logo--width" Image={Logo} />
      </Link>
      <Link to="/rooms" onClick={() => {
        setIsOpen(false);
      }}>
        Rooms
        </Link>
      <Link to="/specials" onClick={() => {
        setIsOpen(false);
      }}>
        Specials
        </Link>
      <Link to="/packages" onClick={() => {
        setIsOpen(false);
      }}>
        Packages
          </Link>
      <Link to="/thingstodo" onClick={() => {
        setIsOpen(false);
      }}>
        Activities
        </Link>
      <Link to="/dining" onClick={() => {
        setIsOpen(false);
      }}>
        Dining
        </Link>
      <Link to="/gallery" onClick={() => {
        setIsOpen(false);
      }}>
        Gallery
        </Link>
      <Link to="/about" onClick={() => {
        setIsOpen(false);
      }}>
        About
        </Link>
      <Link to="/meetings" onClick={() => {
        setIsOpen(false);
      }}>
        Meetings
      </Link>
      <Link to="/press" onClick={() => {
        setIsOpen(false);
      }}>
        Press
      </Link>
    </div>
  </div>
  );
}

const NavBar = ({ cmsData }) => {
  const { CoreData, Navigation } = cmsData;
  const { Logo, LogoWhite } = Navigation;
  return (
    <React.Fragment>
      <div className="hide-on-lg">
        <DesktopNavbar Logo={Logo} CoreData={CoreData}/>
      </div>
      <div className="block lg:hidden">
        <MobileNavbar Logo={Logo} CoreData={CoreData} />
      </div>
    </React.Fragment>
  );
}

export default withRouter(NavBar);