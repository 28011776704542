import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom'
import { AiFillInstagram, AiFillFacebook } from 'react-icons/ai';

import { Img } from "component/FrameL";

import BespokeLogo from "assets/bespoke_footer_icon.png";
import './style.css';

const Footer = ({ cmsData }) => {
    const { HotelName, HotelAddress1, HotelAddress2, PhoneLocal, Facebook, Instagram, Email } = cmsData;
    return <>
        <footer className="relative bg-cover px-4 md:px-8 flex flex-row flex-wrap justify-between text-lg items-center z-0 footer__container text-white bg-primary-blue leading-normal">
            <div className="w-full sm:w-1/2 lg:w-4/12 text-center flex flex-col my-8">
                <span>
                    {HotelAddress1}
                </span>
                <span>
                    {HotelAddress2}
                </span>
                <span className="mt-4">
                    Phone: {PhoneLocal}
                </span>
                <span>
                    Email: <a className="text-white" href={`mailto:${Email}`}>{Email}</a>
                </span>
            </div>

            <stack-l space="var(--s-4)" class="w-full sm:w-1/2 lg:w-4/12 text-center bg-white text-primary-blue items-center py-4 my-4">
                <HashLink to="/about#email-signup" className="uppercase">Email Signup</HashLink>
                <p>For the latest news and last minute specials</p>
                <br />
                <p className="uppercase">Follow Us</p>
                <div className="flex flex-row items-center text-4xl">
                    <a href={Instagram} target="_blank" aria-label="Instagram Link">
                        <AiFillInstagram></AiFillInstagram>
                    </a>
                    <a href={Facebook} target="_blank" aria-label="Facebook Link">
                        <AiFillFacebook></AiFillFacebook>
                    </a>
                </div>
            </stack-l>

            <div className="w-full lg:w-4/12 flex items-center justify-between lg:justify-around my-8">
                <stack-l space="var(--s-4)" class="text-center p-4">
                    <Link className="text-white" to="/policies">Policies &amp; Accessibility</Link>
                    <Link className="text-white" to="/blog">Blog</Link>
                    <p>Copyright {new Date().getFullYear()} {HotelName} &reg;</p>
                </stack-l>

                <div className="flex flex-col items-center lg:items-end">
                    <Img isExternal={false} src={BespokeLogo} alt="Bespoke Logo" />
                </div>
            </div>
        </footer>
    </>;
};

export default Footer;
