import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import App from './App';

import './styles/tailwind_output.css';
import './index.css';
import './component/layout'

import * as serviceWorker from './serviceWorker';
import history from './utils/history.js';


const Index = () => {
  React.useEffect(() => {
    setTimeout(async () => {
      const ReactGA = (await import('react-ga')).default;
      const ReactPixel = (await import('react-facebook-pixel')).default;
      const trackingId = "UA-3588948-1"; // Replace with your Google Analytics tracking ID
      ReactGA.initialize(trackingId, {
        gaOptions: { allowLinker: true },
      });

      ReactGA.ga('require', 'linker');
      ReactGA.ga('linker:autoLink', ['secure.thinkreservations.com', 'thinkreservations.com']);

      ReactGA.ga('send', 'pageview', window.location.pathname);

      const pixelOptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      const pixelId = "206874068032780";
      ReactPixel.init(pixelId, {}, pixelOptions);
      ReactPixel.trackSingle(pixelId, "PageView");

      history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page 
        ReactPixel.trackSingle(pixelId, "PageView");
      });
    }, 10000);
  }, []);

  return <React.StrictMode>
    <Router history={history}>
      <App />
    </Router>
  </React.StrictMode>
}

ReactDOM.render(
  <Index />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

